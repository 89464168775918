<app-header></app-header>

<article class="article">
  <section class="page_header">
    <div class="container">
      <ul class="breadcrumbs">
        <li><a routerLink="/home">Home</a></li>
        <li class="active">Gallery</li>
      </ul>
      <h1 class="page_title">Gallery</h1>
    </div>
  </section>
  <section class="gallery">
    <div class="container">
      <div class="gallery_row">
        <div class="gallery_col" *ngFor="let data of this.galleryList">
          <i class="gallery_img"><img src={{data.image_url}} alt="gallery title here"></i>
          <a class="section_btn xs" href="/album/{{data._id}}"><svg-icon src="assets/img/arrow-right.svg" class="icon"></svg-icon></a>
          <div  [routerLink]="['/album/',data._id]"  class="gallery_title">{{data.title}}</div>
        </div>
      </div>
    </div>
  </section>
</article>

<app-footer></app-footer>