<header class="header">
    <div class="container">
      <nav class="navbar">
        <a class="navbar_logo" href="/"><img src="assets/img/logo.png" alt="IIA Kerala"></a>
        <button type="button" class="navbar_toggler" (click)="is_show=!is_show" [ngClass]="{'show':is_show}">
          <span class="navbar_icon icon1"></span>
          <span class="navbar_icon icon2"></span>
          <span class="navbar_icon icon3"></span>
        </button>
        <div class="navbar_collapse" [ngClass]="{'show':is_show}">
          <ul class="navbar_nav">
            <li [routerLinkActive]="['active']" class="nav_item"><a class="nav_link" routerLink="/home">Home</a></li>
            <li [routerLinkActive]="['active']" class="nav_item"><a class="nav_link" routerLink="/about">About Us</a></li>
            <li [routerLinkActive]="['active']" class="nav_item"><a class="nav_link" href="#center">IIA Centers</a></li>
            <li [routerLinkActive]="['active']" class="nav_item"><a class="nav_link" routerLink="/announcement">Announcements</a></li>
            <li [routerLinkActive]="['active']" class="nav_item"><a class="nav_link" routerLink="/gallery">Gallery</a></li>
            <li [routerLinkActive]="['active']" class="nav_item"><a class="nav_link" routerLink="/contact">Contact Us</a></li>
          </ul>
        </div>
      </nav>
    </div>
</header>