import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../api.service';
import { Lightbox } from 'ngx-lightbox';
@Component({
  selector: 'app-album',
  templateUrl: './album.component.html',
  styleUrls: ['./album.component.scss']
})
export class AlbumComponent implements OnInit {
  album: any;
  albumlist: any;
  name = 'Angular';
  albums:any = [];
  constructor(private apiService: ApiService , private _lightbox: Lightbox) {}

  ngOnInit(): void {
    var Id = window.location.pathname.substring(window.location.pathname.lastIndexOf('/') + 1)
    this.apiService.getGallery(Id).subscribe((data)=>{
      this.album = data;
      this.albumlist =  this.album.data;
      for (var i = 0; i < this.albumlist.length; i++) {
        this.albumlist[i]
        let src =  this.albumlist[i].image_url;
        let caption =  this.albumlist[i].title;
        let thumb =  this.albumlist[i].title;
        let albumLists = {
          src: src, 
          caption: caption,
          thumb: thumb
       };
       this.albums.push(albumLists);
       }     
    });
  }
    open(index: number): void {
    this._lightbox.open(this.albums, index);
    }
    
    close(): void {
    this._lightbox.close();
    }

}
