import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../api.service';

@Component({
  selector: 'app-gallery',
  templateUrl: './gallery.component.html',
  styleUrls: ['./gallery.component.scss']
})
export class GalleryComponent implements OnInit {
  gallery: any;
  galleryList: any;
  constructor(private apiService: ApiService) { }

  ngOnInit(): void {
      this.apiService.getAlbum().subscribe((data)=>{
        this.gallery = data;
        this.galleryList =  this.gallery.data;
      });
  }

}
