import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../api.service';
declare var require: any
const FileSaver = require('file-saver');
@Component({
  selector: 'app-announcement',
  templateUrl: './announcement.component.html',
  styleUrls: ['./announcement.component.scss']
})
export class AnnouncementComponent implements OnInit {
  
  announcement: any;
  announcements: any;
  constructor(private apiService: ApiService) { }

  ngOnInit(): void {
    // Announcement
    this.apiService.getAnnouncement().subscribe((data)=>{
      this.announcement = data;
      this.announcements =  this.announcement.data;

    });
  }


  downloadPdf() {
    let result = this.announcements.map((val: any) => val 
    );

    const pdfUrl = result[0].pdf_url;
    const pdfName = 'your_pdf_file';
    FileSaver.saveAs(pdfUrl, pdfName);

  }

}
