<footer class="footer">
  <div class="container">
    <section class="top_footer">
      <div class="footer_row">
        <div class="footer_col">
          <a class="footer_logo" href="/"><img src="assets/img/logo.png" alt="IIA Kerala Chapter"></a>
          <ul class="footer_list">
            <li>
              <svg-icon src="assets/img/map.svg" class="icon"></svg-icon>
              <span class="footer_text">IIA Kerala Chapter, Gangothri, Choorapoika, Karamcode PO,<br> Kollam - 691579</span>
            </li>
            <li>
              <svg-icon src="assets/img/mail.svg" class="icon"></svg-icon>
              <a href="#" class="footer_text">iiakeralachapter@gmail.com</a>
            </li>
          </ul>
        </div>
        <div class="footer_col">
          <span class="footer_title">Pages</span>
          <ul class="footer_link">
            <li><a routerLink="/home">Home</a></li>
            <li><a routerLink="/about">About Us</a></li>
            <li><a href="#center">IIA Centers</a></li>
            <li><a routerLink="/announcement">Announcements</a></li>
            <li><a routerLink="/gallery">Gallery</a></li>
            <li><a routerLink="/contact">Contact Us</a></li>
          </ul>
          <ul class="footer_social">
            <li><a href="https://www.instagram.com/iiakeralachapter/" target="_blank"><svg-icon src="assets/img/instagram.svg" class="icon"></svg-icon></a></li>
          </ul>
        </div>
        <div class="footer_col">
          <span class="footer_title">Subscribe Now</span>
          <p class="footer_text">To receive latest updates and  alerts, kindly subscribe below using your ID numbers. Exclusively for existing IIA membership holders.</p>
          <div class="card-body">
            <form class="footer_form" [formGroup]="registerForm" (ngSubmit)="onSubmit()">
              <div class="row">
  
                <div class="col-lg-6">
                  <input type="text" formControlName="name"  id="name" placeholder="Your Name" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.name.errors }" />
                  <div *ngIf="submitted && f.name.errors" class="invalid-feedback">
                      <div *ngIf="validate == true && f.name.errors.required" class="error">Name is required</div>
                  </div>
                </div>
  
                <div class="col-lg-6">
                  <input type="email" formControlName="email" id="email" placeholder="Email Address" [ngClass]="{ 'is-invalid': submitted && f.email.errors }">
                  <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                    <div *ngIf="validate == true && f.email.errors.required" class="error">Email is required</div>
                  </div>
                </div>
  
                <div class="col-lg-6">
                  <input type="phone" formControlName="phone" id="phone" placeholder="Phone Number" [ngClass]="{ 'is-invalid': submitted && f.phone.errors }">
                  <div *ngIf="submitted && f.phone.errors" class="invalid-feedback">
                    <div *ngIf="validate == true && f.phone.errors.required" class="error">Phone number is required</div>
                  </div>
                </div>
                <div class="col-lg-6">
                  <input type="text" formControlName="iianumber" id="number" placeholder="IIA Number" [ngClass]="{ 'is-invalid': submitted && f.iianumber.errors }">
                  <div *ngIf="submitted && f.iianumber.errors" class="invalid-feedback">
                    <div *ngIf="validate == true && f.iianumber.errors.required" class="error">IIA number is required</div>
                  </div>
                </div>
  
                <div class="col-lg-12">
                  <button type="submit" class="section_btn sm">
                    <svg-icon src="assets/img/arrow-right.svg" class="icon"></svg-icon>
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
    
    <section class="footer_bottom">
      <div class="row">
        <div class="col-lg-6">
          <a href="#" class="copyright">www.indianinstituteofarchitects.com</a>
          <a href="#" class="copyright">www.abskerala.in</a>
        </div>
        <div class="col-lg-6 text_right">
          <span class="copyright">© 2021 IIA Kerala Chapter  |  All rights reserved</span>
          <span class="copyright">Powered by Eximuz Technolabs</span>
        </div>
      </div>
    </section>
  </div>
</footer>