import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../api.service';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss']
})
export class AboutComponent implements OnInit {
  aboutData: any;
  about: any;
  team: any;
  teamData: any;
  vertical: any;
  verticalList: any;
  verticalTeams: any;
  verticalTeam: any;
  id: any;
  member: any;
  members: any;
  verticalData: any;
  memberArray: any;
  executiveArray: any;
  verticalArray: any;

  constructor(private apiService: ApiService) { }

  ngOnInit(): void {
  // About
  this.apiService.getChapter().subscribe((data)=>{
    this.aboutData = data;
    this.about =  this.aboutData.data[0];
  });

  // Team
  this.apiService.getTeam().subscribe((data)=>{
    this.team = data;
    this.teamData =  this.team.data;
  });

  // Members
  this.apiService.getMembersAbout().subscribe((data)=>{
    this.member = data;
    this.members =  this.member.data;
  });

  // Vertical
  this.apiService.getVertical().subscribe((data)=>{
    this.vertical = data;
    this.verticalList =  this.vertical.data;
  });

  }

}
