<app-header></app-header>

<article class="article">
  <section class="page_header">
    <div class="container">
      <ul class="breadcrumbs">
        <li><a routerLink="/home">Home</a></li>
        <li class="active">About Us</li>
      </ul>
      <h1 class="page_title">About Us</h1>
      <p class="section_text">{{about.description}}</p>
    </div>
  </section>

  <section class="team">
    <div class="container">
      <h3 class="section_title">Our Team</h3>
      <div class="team_row">
        <div class="team_col" *ngFor="let data of this.teamData">
          <i class="team_img"><img src={{data.image_url}} alt="Ar. L Gopakumar"></i>
          <div class="team_data">
            <h5 class="team_title">{{data.name}}</h5>
            <span class="team_meta">{{data.designation}}</span>
            <!-- <div class="team_contact">
              <a class="cnt_link" href="mailto:{{data.emailid}}"><svg-icon src="assets/img/mail.svg" class="icon"></svg-icon>{{data.emailid}}</a>
              <a class="cnt_link" href="tel:{{data.mobileno}}"><svg-icon src="assets/img/phone.svg" class="icon"></svg-icon>{{data.mobileno}}</a>
            </div> -->
          </div>
        </div>
      </div>
      <!-- <div class="members_wrap">
        <h3 class="section_title">Executive Committee Members</h3>
        <div class="" *ngFor="let data of this.members">
            <div class="">
              <h5 class="">{{data.name}}</h5>
            </div>
        </div>
      </div> -->
    </div>
  </section>

  <section class="vertical">
    <div class="container">
      <h3 class="section_title">Verticals</h3>
      <div class="vertical_row">
        <div class="vertical_col" *ngFor="let data of this.verticalList">
          <h3 class="vertical_title">{{data.vertical[0].title}}</h3>
          <p class="section_text">{{data.vertical[0].description}}</p>
          <div class="member_row">
            <div class="vertical_member">
              <i class="member_img"><img src={{data.executive[0].image_url}} alt="Member Name"></i>
              <h6 class="member_name">{{data.executive[0].name}}</h6>
              <span class="member_meta">{{data.executive[0].designation}}</span>
            </div>
            <div class="vertical_member" *ngIf="data.executive.length > 2 || data.executive.length === 2 ">
              <i class="member_img"><img src={{data.executive[1].image_url}} alt="Member Name"></i>
              <h6 class="member_name">{{data.executive[1].name}}</h6>
              <span class="member_meta">{{data.executive[1].designation}}</span>
            </div>
            <div class="vertical_member" *ngIf="data.executive.length === 3  ">
              <i class="member_img"><img src={{data.executive[2].image_url}} alt="Member Name"></i>
              <h6 class="member_name">{{data.executive[2].name}}</h6>
              <span class="member_meta">{{data.executive[2].designation}}</span>
            </div>

          </div>
        </div>
      </div>
    </div>
  </section>
</article>

<app-footer></app-footer>