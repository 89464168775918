import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-live',
  templateUrl: './live.component.html',
  styleUrls: ['./live.component.scss']
})
export class LiveComponent implements OnInit {

  constructor(private router: Router) { }
  showLoader: any = false;
  counter: any = 10;
  counterText: any = '10'
  ngOnInit(): void {

  }
  show() {
    this.showLoader = true;
    let self = this;
    let timer = setInterval(function () {
      self.counter = self.counter - 1
      if (self.counter == 0) {
        self.counterText = "Go!"
      } else {
        self.counterText = self.counter
      }
      if (self.counter < 0) {
        clearInterval(timer);
        self.router.navigate(['/home'])
      }
      // self.router.navigate(['/home'])
    }, 1000)
    //  setTimeout(function(){
    //   self.showLoader=false;
    //   self.router.navigate(['/home'])
    //  },3600) 
  }



}
