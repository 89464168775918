<section class="live">
      <div class="live_container">
        <i class="live_logo"><img src="assets/img/logo.png" alt="IIA Kerala Chapter"></i>
        <h1 class="live_title">Webiste launch</h1>
        <button (click)="show()"  *ngIf="!showLoader" class="live_btn">Go Live</button>
        <span class="live_loading" *ngIf="showLoader">
          <span class="load"></span>
          <span class="load"></span>
          <span class="load"></span>
          <span class="load"></span>
          <span class="live_count">{{counterText}}</span>
        </span>
        <a class="live_power" href="https://eximuz.com/" target="_blank">
          <span>Powered By</span>
          <img src="assets/img/eximuz.png" alt="Eximuz Technolabs">
          <span>Eximuz Technolabs</span>
        </a>
      </div>
</section>