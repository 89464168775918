import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ApiService } from '../../api.service';
import axios from 'axios';
import { ToastrService } from 'ngx-toastr';


@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  registerForm!: FormGroup;
  submitted = false;
  validate = true;
  name: any;
  phone: any;
  number: any;
  email: any;

  constructor(private formBuilder: FormBuilder , private apiService: ApiService ,
     private toastr: ToastrService) { }



    ngOnInit() {
    this.registerForm = this.formBuilder.group({
        name: ['', Validators.required],
        email: ['', [Validators.required, Validators.email]],
        iianumber: ['', [Validators.required, Validators.minLength(6)]],
        phone: ['', Validators.required],
    });
    }
    get f() { return this.registerForm.controls; }

    onSubmit(){
        this.submitted = true;
        let nameVal = this.registerForm.value.name;
        let emailVal = this.registerForm.value.email;
        let phoneVal = this.registerForm.value.phone;
        let numberVal = this.registerForm.value.iianumber;
        let base = this.apiService.baseURL
        axios.post(`${base}/api/v1/subscription`, {
            name: nameVal, 
            email: emailVal,
            phone: phoneVal,
            number: numberVal
          }, {
            headers: {
              'content-type': 'application/json',
              authorization: 'Bearer 123abc456def'
            }}
          )
          .then((res: any) => {
            if(res.data.status === "true"){
              this.registerForm.reset();
              this.validate = false;
            }
          })
          .catch((error : any) => {
            console.error(error)
          })
    }

}
