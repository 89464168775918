<app-header></app-header>

<article class="article">
  <section class="page_header">
    <div class="container" *ngFor="let data of this.aboutData">
      <ul class="breadcrumbs">
        <li><a routerLink="/home">Home</a></li>
        <li><a href="#center">Centers</a></li>
        <li class="active">{{data.title}}</li>
      </ul>
      <h1 class="page_title">{{data.title}}</h1>
      <p class="section_text">{{data.description}}</p>
    </div>
  </section>

  <section class="team">
    <div class="container">
      <h3 class="section_title">Our Team</h3>
      <div class="team_row">
        <div class="team_col" *ngFor="let data of this.teams">
          <i class="team_img"><img src={{data.image_url}} alt="Ar. L Gopakumar"></i>
          <div class="team_data">
            <h5 class="team_title">{{data.name}}</h5>
            <span class="team_meta">{{data.designation}}</span>

          </div>
        </div>
      </div>
      
      <div class="members_wrap">
        <h3 class="section_title">Executive Committee Members</h3>
        <ul class="members_exe">
            <li *ngFor="let data of this.members">{{data.name}}</li>
        </ul>
      </div>

    </div>
  </section>
</article>

<app-footer></app-footer>