import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ApiService } from '../../api.service';
import axios from 'axios';
import { ToastrService } from 'ngx-toastr';


@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {
  contactForm!: FormGroup;
  submitted = false;
  validate = true;

  constructor(private formBuilder: FormBuilder,
              private apiService: ApiService) { }

  ngOnInit() {
    this.contactForm = this.formBuilder.group({
        name: ['', Validators.required],
        email: ['', [Validators.required, Validators.email]],
        iianumber: ['', [Validators.required, Validators.minLength(6)]],
        phone: ['', Validators.required],
        message: ['', Validators.required],
    });
  }
  get f() { return this.contactForm.controls; }

  onSubmit(){
    this.submitted = true;
    let nameVal = this.contactForm.value.name;
    let emailVal = this.contactForm.value.email;
    let phoneVal = this.contactForm.value.phone;
    let numberVal = this.contactForm.value.iianumber;
    let messageVal = this.contactForm.value.message;
    let base = this.apiService.baseURL
    axios.post(`${base}/api/v1/contact`, {
        name: nameVal, 
        email: emailVal,
        phone: phoneVal,
        number: numberVal,
        message: messageVal
      }, {
        headers: {
          'content-type': 'application/json',
          authorization: 'Bearer 123abc456def'
        }}
      )
      .then((res: any) => {
        if(res.data.status === "true"){
          this.contactForm.reset();
          this.validate = false;
        }
      })
      .catch((error : any) => {
        console.error(error)
      })
  }


}
