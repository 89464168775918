<app-header></app-header>

<article class="article">
  <section class="page_header">
    <div class="container">
      <ul class="breadcrumbs">
        <li><a routerLink="/home">Home</a></li>
        <li><a href="/gallery">Gallery</a></li>
        <li class="active">Album Name</li>
      </ul>
      <h1 class="page_title">Album Name</h1>
    </div>
  </section>
  <section class="album">
    <div class="container">
      <div class="album_row">
        <div class="album_col"  *ngFor="let image of albums; let i=index" >
          <a  class="album_img" (click)="open(i)"><img  src={{image.src}} alt="album title here"></a>
          <svg-icon class="icon" src="assets/img/zoom.svg"></svg-icon>
      </div>
    </div>
    </div>
  </section>
</article>

<app-footer></app-footer>