<app-header></app-header>

<article class="article">
  <section class="page_header">
    <div class="container">
      <ul class="breadcrumbs">
        <li><a routerLink="/home">Home</a></li>
        <li class="active">Contact Us</li>
      </ul>
      <h1 class="page_title">Contact Us</h1>
    </div>
  </section>
  <section class="contact">
    <div class="container">
      <div class="contact_row">
        <div class="contact_col">
          <h3 class="contact_title">Reach Us</h3>
          <div class="contact_data">
            <ul class="contact_list">
              <li>
                <svg-icon src="assets/img/map.svg" class="icon"></svg-icon>
                <span class="contact_text">IIA Kerala Chapter, Gangothri, Choorapoika, Karamcode PO,<br> Kollam - 691579</span>
              </li>
              <li>
                <svg-icon src="assets/img/mail.svg" class="icon"></svg-icon>
                <a href="#" class="contact_text">iiakeralachapter@gmail.com</a>
              </li>
            </ul>
            <ul class="contact_social">
              <li><a href="https://www.instagram.com/iiakeralachapter/" target="_blank"><svg-icon src="assets/img/instagram.svg" class="icon"></svg-icon></a>instagram</li>
            </ul>
          </div>
        </div>
        <div class="contact_col">
          <div class="contact_form">
            <h3 class="contact_title">Leave Us a Message</h3>
            <p class="section_text">For enquiries and information related to IIA, kindly contact us on the below. Our team member will get back to your query at the earliest.</p>
            <form class="footer_form" [formGroup]="contactForm" (ngSubmit)="onSubmit()">
              <div class="row">
  
                <div class="col-lg-6">
                  <input type="text" formControlName="name"  id="name" placeholder="Your Name" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.name.errors }" />
                  <div *ngIf="submitted && f.name.errors" class="invalid-feedback">
                      <div *ngIf="validate == true && f.name.errors.required" class="error">Name is required</div>
                  </div>
                </div>
  
                <div class="col-lg-6">
                  <input type="email" formControlName="email" id="email" placeholder="Email Address" [ngClass]="{ 'is-invalid': submitted && f.email.errors }">
                  <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                    <div *ngIf="validate == true && f.email.errors.required" class="error">Email is required</div>
                  </div>
                </div>
  
                <div class="col-lg-6">
                  <input type="phone" formControlName="phone" id="phone" placeholder="Phone Number" [ngClass]="{ 'is-invalid': submitted && f.phone.errors }">
                  <div *ngIf="submitted && f.phone.errors" class="invalid-feedback">
                    <div *ngIf="validate == true && f.phone.errors.required" class="error">Phone number is required</div>
                  </div>
                </div>
                <div class="col-lg-6">
                  <input type="text" formControlName="iianumber" id="number" placeholder="IIA Number" [ngClass]="{ 'is-invalid': submitted && f.iianumber.errors }">
                  <div *ngIf="submitted && f.iianumber.errors" class="invalid-feedback">
                    <div *ngIf="validate == true && f.iianumber.errors.required" class="error">IIA number is required</div>
                  </div>
                </div>

                <div class="col-lg-10">
                  <textarea name="message" class="form-control" formControlName="message"  id="message" placeholder="Message" [ngClass]="{ 'is-invalid': submitted && f.message.errors }"></textarea>
                  <div *ngIf="submitted && f.message.errors" class="invalid-feedback">
                    <div *ngIf="f.message.errors.required" class="error1">Message is required</div>
                  </div>
                </div>
  
                <div class="col-lg-12">
                  <button type="submit" class="section_btn sm">
                    <svg-icon src="assets/img/arrow-right.svg" class="icon"></svg-icon>
                  </button>
                </div>
              </div>
            </form>




















          </div>
        </div>
      </div>
    </div>
  </section>
</article>

<app-footer></app-footer>