import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { AngularSvgIconModule } from 'angular-svg-icon';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './component/header/header.component';
import { HomeComponent } from './views/home/home.component';
import { FooterComponent } from './component/footer/footer.component';
import { AboutComponent } from './views/about/about.component';
import { CenterComponent } from './views/center/center.component';
import { AnnouncementComponent } from './views/announcement/announcement.component';
import { AlbumComponent } from './views/album/album.component';
import { GalleryComponent } from './views/gallery/gallery.component';
import { ContactComponent } from './views/contact/contact.component';
import { ViewportScroller } from '@angular/common';
import { Router, Scroll } from '@angular/router';
import { LiveComponent } from './views/live/live.component';
import { LightboxModule } from 'ngx-lightbox';
import { ReactiveFormsModule } from '@angular/forms';
import { ToastrModule } from 'ngx-toastr';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    HomeComponent,
    FooterComponent,
    AboutComponent,
    CenterComponent,
    AnnouncementComponent,
    AlbumComponent,
    GalleryComponent,
    ContactComponent,
    LiveComponent,
    
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    CarouselModule,
    BrowserAnimationsModule,
    HttpClientModule,
    LightboxModule ,
    ReactiveFormsModule,
    AngularSvgIconModule.forRoot(),
    ToastrModule.forRoot()
  ],
  providers: [ReactiveFormsModule],
  bootstrap: [AppComponent]
})


export class AppModule {

}
