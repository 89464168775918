import { Component, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { ApiService } from '../../api.service';
import { CenterComponent } from '../center/center.component';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  announcement: any;
  announcementDatas: any;
  centerData: any;
  centerDatas: any;
  album: any;
  albums: any;

  constructor(private apiService: ApiService) { }

  homeBanner: OwlOptions = {
    loop: true,
    items: 1,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: true,
    nav: false,
    navSpeed: 700,
    autoplay:true,
    autoplayTimeout:6000,
    autoplayHoverPause:false
  }

  homeMedia: OwlOptions = {
    loop: true,
    margin: 3,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: true,
    nav: false,
    navSpeed: 700,
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 2
      },
      740: {
        items: 3
      },
      940: {
        items: 5
      }
    }
  }

  

  ngOnInit(): void {
    // Announcement
    this.apiService.getAnnouncement().subscribe((data)=>{
      this.announcement = data;
      this.announcementDatas = this.announcement.data;
    });

    // Center
    this.apiService.getCenter().subscribe((data)=>{
      this.centerData = data;
      this.centerDatas =  this.centerData.data;

   
    });

    // Album
    this.apiService.getAlbum().subscribe((data)=>{
      this.album = data;
      this.albums =  this.album.data;
    });



    

  }
}
