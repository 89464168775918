import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../api.service';

@Component({
  selector: 'app-center',
  templateUrl: './center.component.html',
  styleUrls: ['./center.component.scss']
})
export class CenterComponent implements OnInit {
  team: any;
  teams: any;
  about: any;
  aboutData: any;
  member: any;
  members: any;


  constructor(private apiService: ApiService) {}

  ngOnInit(): void {
    // Center Details
    var Id = window.location.pathname.substring(window.location.pathname.lastIndexOf('/') + 1)
    this.apiService.getInnerCenter(Id).subscribe((data)=>{
      this.about = data;
      this.aboutData =  this.about.data;
    });

    // Team list
    this.apiService.getCenterTeam(Id).subscribe((data)=>{
      this.team = data;
      this.teams =  this.team.data;
    });

    // Exicutives members
    this.apiService.getKeycenter(Id).subscribe((data)=>{
      this.member = data;
      this.members =  this.member.data;
    });

  }

}
